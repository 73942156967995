const ManualActivityFields=[
    {
        label:"Name",
        value:"title"
    },
    {
        label:"Duration",
        value:"duration"
    },
    {
        label:"City",
        value:"city"
    },
    {
        label:"Start Time",
        value:"startTime"
    },
    {
        label:"Description",
        value:"description"
    },
    {
        label:"Additional Information",
        value:"additionalInformation"
    },
    {
        label:"Tags",
        value:"activityProductTags"
    },
    {
        label:"Max Participants",
        value:"activityMaxPeople"
    },
    {
        label:"Suitable for Vegetarians",
        value:"vegetarianFriendly"
    }
]

export {
    ManualActivityFields
}