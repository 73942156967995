import { useNotification } from "@magicbell/react-headless";
// @mui
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Avatar from "@mui/material/Avatar";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemButton from "@mui/material/ListItemButton";
// utils
import { fToNow } from "src/utils/format-time";
import { useSnackbar } from "src/components/snackbar";
// components
import { useLocales } from "src/locales";
import { getImageFromType, Reader } from "./utils";

export default function NotificationItem({
    rawNotification,
    handleOpenDialog,
}) {
    const { t, currentLang } = useLocales();
    const notification = useNotification(rawNotification);
    const { enqueueSnackbar } = useSnackbar();

    const handleDialogOpen = async () => {
        handleOpenDialog(notification);
        if (!notification.readAt) {
            try {
                await notification.markAsRead();
            } catch (error) {
                console.error(error);
                enqueueSnackbar("Error marking notification as read", {
                    variant: "error",
                });
            }
        }
    };
    return (
        <>
            <ListItemButton
                disableRipple
                onClick={handleDialogOpen} // Open the dialog when the notification is clicked
                sx={{
                    p: 2.5,
                    alignItems: "flex-start",
                    borderBottom: (theme) =>
                        `dashed 1px ${theme.palette.divider}`,
                }}
            >
                {!notification.readAt && (
                    <Box
                        sx={{
                            top: 26,
                            width: 8,
                            height: 8,
                            right: 20,
                            borderRadius: "50%",
                            bgcolor: "info.main",
                            position: "absolute",
                        }}
                    />
                )}

                {/* AVATAR */}
                <RenderAvatar
                    customAttributes={notification.customAttributes}
                    type={notification.customAttributes?.type}
                />

                {/* TEXT */}
                <Stack sx={{ flexGrow: 1 }}>
                    <ListItemText
                        disableTypography
                        primary={Reader(notification.title)}
                        secondary={
                            <Stack
                                direction="row"
                                alignItems="center"
                                sx={{
                                    typography: "caption",
                                    color: "text.disabled",
                                }}
                                divider={
                                    <Box
                                        sx={{
                                            width: 2,
                                            height: 2,
                                            bgcolor: "currentColor",
                                            mx: 0.5,
                                            borderRadius: "50%",
                                        }}
                                    />
                                }
                            >
                                {fToNow(
                                    notification.sentAt.toDate(),
                                    currentLang.value,
                                )}
                                {t(notification.customAttributes?.category)}
                            </Stack>
                        }
                    />
                    {/* CTA */}
                    {/* {notification.type === "email" && (
                        <Stack alignItems="flex-start">
                            <Box
                                sx={{
                                    p: 1.5,
                                    my: 1.5,
                                    borderRadius: 1.5,
                                    color: "text.secondary",
                                    bgcolor: "background.neutral",
                                }}
                            >
                                {Reader(
                                    `${notification.customAttributes?.from} replied to ${notification.customAttributes?.subject}`,
                                )}
                            </Box>
                        </Stack>
                    )} */}
                    {/* {notification.actionUrl &&
                        notification.customAttributes?.actionTitle && (
                            <Stack alignItems="flex-start">
                                <Box
                                    sx={{
                                        p: 1.5,
                                        my: 1.5,
                                        borderRadius: 1.5,
                                        color: "text.secondary",
                                        bgcolor: "background.neutral",
                                    }}
                                >
                                    {notification.customAttributes?.actionTitle}
                                </Box>
                            </Stack>
                        )} */}
                </Stack>
            </ListItemButton>
        </>
    );
}

function RenderAvatar({ type, customAttributes }) {
    return (
        <ListItemAvatar>
            {customAttributes?.avatarUrl ? (
                <Avatar
                    src={customAttributes.avatarUrl}
                    sx={{ bgcolor: "background.neutral" }}
                />
            ) : (
                <Stack
                    alignItems="center"
                    justifyContent="center"
                    sx={{
                        width: 40,
                        height: 40,
                        borderRadius: "50%",
                        bgcolor: "background.neutral",
                    }}
                >
                    <Box
                        component="img"
                        src={getImageFromType(type)}
                        sx={{ width: 24, height: 24 }}
                    />
                </Stack>
            )}
        </ListItemAvatar>
    );
}
