export const getImageFromType = (type) => {
    switch (type) {
        case "order":
            return "/assets/icons/notification/ic_order.svg";
        case "chat":
            return "/assets/icons/notification/ic_chat.svg";
        case "email":
            return "/assets/icons/notification/ic_mail.svg";
        case "delivery":
            return "/assets/icons/notification/ic_delivery.svg";
        case "customer":
            return "/assets/icons/notification/ic_customer.svg";
        default:
            return "/assets/icons/notification/ic_notification.svg";
    }
};
