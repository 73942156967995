import languages from "../components/languages/languagesLabelWithCode.json";

const getLanguageCode = (language = "English") => {
    const languageData = languages.find(
        (lang) => lang.label === language,
    );

    return languageData?.value;
};

export { getLanguageCode };
