import { axiosInstance } from "src/config/axiosInstance";

const SPECIAL_TOKEN = process.env.REACT_APP_TZ_API_KEY;

class FlightsService {
    static async fetchFlightOffers(searchCriteria, maxRetries, retryDelay) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.post(
                    `/offers`,
                    searchCriteria,
                    {
                        headers: {
                            "Content-Type": "application/json",
                        },
                    },
                );

                return response.data; // Assuming the flights data is in the response's data property
            } catch (error) {
                console.error("Error creating flight:", error);
                retries++;
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
        return [];
    }

    static async bookFlight(bookingData, maxRetries, retryDelay) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.post(
                    `flights/create`,
                    bookingData,
                    {
                        headers: { "Content-Type": "application/json" },
                    },
                );
                return response.data;
            } catch (error) {
                console.error(
                    "An error occurred while booking flight:",
                    error.message,
                );
                retries++;
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
    }

    static async deleteFlightById(flightId) {
        try {
            const response = await axiosInstance.post(
                `/flights/delete/${flightId}`,
            );
            return response;
        } catch (error) {
            console.error("An error occurred:", error);
        }
    }

    static async fetchFlightDetails(flightId, maxRetries, retryDelay) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.get(
                    `/flights/${flightId}`,
                );
                return response;
            } catch (error) {
                console.error("Error fetching flight details:", error);
                retries++;
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
    }

    static async fetchFlightDetailsForProject(
        projectId,
        maxRetries,
        retryDelay,
    ) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.get(
                    `/flights/project/${projectId}`,
                );
                return response;
            } catch (error) {
                console.error("Error fetching flight details:", error);
                retries++;
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
    }

    static async createFlight(data, maxRetries, retryDelay) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.post(
                    `/flights/new`,
                    data,
                    {
                        headers: {
                            "Content-Type": "application/json",
                        },
                    },
                );

                return response.data; // Assuming the project data is in the response's data property
            } catch (error) {
                console.error("Error creating flight:", error);
                retries++;
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
    }

    static async createFlightsProposal(
        proposalData,
        maxRetries = 3,
        retryDelay = 2000,
    ) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.post(
                    "/flights/createFlightsProposal",
                    proposalData,
                );
                return response;
            } catch (error) {
                console.error(
                    "An error occurred while creating flights proposal:",
                    error.message,
                );
                retries++;
                if (retries === maxRetries) {
                    throw error;
                }
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
    }
    static async selectFlightOfProposal(
        flightData,
        maxRetries = 3,
        retryDelay = 2000,
        viewType,
    ) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.post(
                    "/flights/selectFlightOfProposal",
                    flightData,
                    viewType === "agent"
                        ? undefined
                        : { headers: { "tz-api-key": SPECIAL_TOKEN } },
                );
                return response;
            } catch (error) {
                console.error(
                    "An error occurred while selecting flights proposal:",
                    error.message,
                );
                retries++;
                if (retries === maxRetries) {
                    throw error;
                }
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
    }
    static async checkProposalOffer(
        projectId,
        maxRetries = 3,
        retryDelay = 2000,
    ) {
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.get(
                    "/flights/checkProposalOffer/" + projectId,
                );
                return response;
            } catch (error) {
                console.error(
                    "An error occurred while checking flight offer proposal:",
                    error.message,
                );
                retries++;
                if (retries === maxRetries) {
                    throw error;
                }
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
    }

    static async getFlightOrderDetails(
        orderId,
        maxRetries = 3,
        retryDelay = 2000,
    ){
        let retries = 0;
        while (retries < maxRetries) {
            try {
                const response = await axiosInstance.get(
                    "/flights/order/details/" + orderId,
                );
                return response?.data;
            } catch (error) {
                console.error(
                    "An error occurred while getting flight order details:",
                    error.message,
                );
                retries++;
                if (retries === maxRetries) {
                    throw error;
                }
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
    }

    static async createPendingCancellation(
        bookingId,
        maxRetries = 3,
        retryDelay = 2000,
    ){
        let retries=0;
        while(retries < maxRetries){
            try{

                const response = await axiosInstance.post(
                    "/flights/create/pendingCancel/",
                    { bookingId },
                );
                return response.data;

            }catch(error){
                console.error(
                    "An error occurred while creating pending cancellation:",
                    error.message,
                );
                retries++;
                if (retries === maxRetries) {
                    throw error;
                }
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
    }

    static async getCancellationDetails(
        bookingId,
        maxRetries = 3,
        retryDelay = 2000,
    ){
        let retries=0;
        while(retries < maxRetries){
            try{

                const response = await axiosInstance.get(
                    "/flights/cancellation/details/" + bookingId,
                );
                return response.data;

            }catch(error){
                console.error(
                    "An error occurred while getting cancellation details:",
                    error.message,
                );
                retries++;
                if (retries === maxRetries) {
                    throw error;
                }
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
    }

    static async confirmCancelOrder(
        bookingId,
        maxRetries = 3,
        retryDelay = 2000,
    ){
        let retries=0;
        while(retries < maxRetries){
            try{

                const response = await axiosInstance.put(
                    "/flights/confirm/cancelOrder",
                    {
                        bookingId,
                    }
                );
                return response.data;

            }catch(error){
                console.error(
                    "An error occurred while confirming cancellation:",
                    error.message,
                );
                retries++;
                if (retries === maxRetries) {
                    throw error;
                }
                await new Promise((resolve) => setTimeout(resolve, retryDelay));
            }
        }
    }

    static async findFreshFlightOffer(
        flight,
        flightParams,
    ){
        try{

            const response=await axiosInstance.post(
                "/flights/find/flightOffer",
                {
                    flight,
                    flightParams
                }
            )

            return response.data;

        }catch(error){
            throw error;
        }
    }
    
    
}

export default FlightsService;
