// @mui
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
// hooks
// routes
import { paths } from "src/routes/paths";
// locales
import { useLocales } from "src/locales";
// components
import Label from "src/components/label";
import { useRouter } from "src/routes/hooks";

// ----------------------------------------------------------------------

export default function NavUpgrade() {
    const user = JSON.parse(sessionStorage.getItem("user"));

    const { t } = useLocales();
    const router = useRouter();

    const handleFeedback = () => {
        router.push(paths.dashboard.kanban);
    };

    return (
        <Stack
            sx={{
                px: 2,
                py: 5,
                textAlign: "center",
            }}
        >
            <Stack alignItems="center">
                <Box sx={{ position: "relative" }}>
                    <Avatar
                        src={`/assets/avatar_25.jpg`}
                        alt={user?.displayName}
                        sx={{ width: 48, height: 48 }}
                    />
                    <Label
                        color="info"
                        variant="filled"
                        sx={{
                            top: -6,
                            px: 0.5,
                            left: 40,
                            height: 20,
                            position: "absolute",
                            borderBottomLeftRadius: 2,
                        }}
                    >
                        {t("Welcome")}
                    </Label>
                </Box>

                <Stack spacing={0.5} sx={{ mt: 1.5, mb: 2 }}>
                    <Typography variant="subtitle2" noWrap>
                        {user?.name} · {user?.userCity}
                    </Typography>

                    <Typography
                        variant="body2"
                        noWrap
                        sx={{ color: "text.disabled" }}
                    >
                        {user?.email}
                    </Typography>
                </Stack>

                <Button
                    variant="contained"
                    onClick={handleFeedback}
                    target="_blank"
                    rel="noopener"
                    sx={{ backgroundColor: "##1a1a33" }}
                >
                    {t("I Have Suggestions")}
                </Button>
            </Stack>
            <Stack spacing={1} sx={{ mt: 3 }}>
                <Typography variant="caption" sx={{ color: "text.secondary" }}>
                    {t("Powered by Travelandz © 2024. All rights reserved")}
                </Typography>
            </Stack>
        </Stack>
    );
}
