import PropTypes from "prop-types";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import ListItemText from "@mui/material/ListItemText";
import FileThumbnail from "src/components/file-thumbnail";
import Label from "src/components/label";
import { fToNow } from "src/utils/format-time";
import { useRouter } from "src/routes/hooks";
import { paths } from "src/routes/paths";
import { useLocales } from "src/locales";
import { Reader } from "./utils";
import { Divider } from "@mui/material";

function NotificationDialog({ open, onClose, notification }) {
    const { t, currentLang } = useLocales();
    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle>{t("Notification Details")}</DialogTitle>
            <DialogContent sx={{ minHeight: "150px" }}>
                {notification !== null && (
                    <>
                        <Typography variant="h6">
                            {t("Details")}: {notification.title}
                        </Typography>
                        {notification.customAttributes?.subject && (
                            <Typography variant="body1">
                                {t("Subject")}:{" "}
                                {notification.customAttributes.subject}
                            </Typography>
                        )}
                        {notification.customAttributes?.from && (
                            <Typography variant="body1">
                                {t("From")}:{" "}
                                {notification.customAttributes.from}
                            </Typography>
                        )}
                        <Typography variant="body1">
                            {t("Notification created on")}:{" "}
                            {notification.sentAt.toDate().toLocaleString()}
                        </Typography>
                        {notification.customAttributes?.category && (
                            <Typography variant="body1">
                                {t("Category")}:{" "}
                                {t(notification.customAttributes.category)}
                            </Typography>
                        )}

                        {/* Add more details about the notification */}
                        <Divider sx={{ marginY: 2 }} />
                        <Stack sx={{ flexGrow: 1 }}>
                            {/* TEXT */}
                            <ListItemText
                                disableTypography
                                primary={Reader(notification.content)}
                                secondary={
                                    <Stack
                                        direction="row"
                                        alignItems="center"
                                        sx={{
                                            typography: "caption",
                                            color: "text.disabled",
                                        }}
                                        divider={
                                            <Box
                                                sx={{
                                                    width: 2,
                                                    height: 2,
                                                    bgcolor: "currentColor",
                                                    mx: 0.5,
                                                    borderRadius: "50%",
                                                }}
                                            />
                                        }
                                    >
                                        {fToNow(
                                            notification.sentAt.toDate(),
                                            currentLang.value,
                                        )}
                                        {t(
                                            notification.customAttributes
                                                ?.category,
                                        )}
                                    </Stack>
                                }
                                sx={{ marginBottom: 2 }}
                            />
                            {/* ACTIONS */}
                            {/* {notification.customAttributes?.type ===
                                "friend" && <FriendAction />} */}

                            {notification.customAttributes?.projectId && (
                                <ProjectAction
                                    onClose={onClose}
                                    {...notification.customAttributes}
                                />
                            )}

                            {/* {notification.customAttributes?.type === "file" && (
                                <FileAction />
                            )}
                            {notification.customAttributes?.type === "tags" && (
                                <TagsAction />
                            )} */}
                            {/* {notification.customAttributes?.type ===
                                "payment" && <PaymentAction />} */}
                        </Stack>
                    </>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    {t("Close")}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

NotificationDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    notification: PropTypes.object.isRequired,
};

function FriendAction() {
    const { t } = useLocales();
    return (
        <Stack spacing={1} direction="row" sx={{ mb: 2 }}>
            <Button size="small" variant="contained">
                {t("Accept")}
            </Button>
            <Button size="small" variant="outlined">
                {t("Decline")}
            </Button>
        </Stack>
    );
}
function ProjectAction({ projectId, onClose }) {
    const router = useRouter();
    const { t } = useLocales();
    const handleOpenProject = async () => {
        console.log("Opening project:", projectId);
        router.push(paths.dashboard.project.profile(projectId));
        onClose();
    };
    return (
        <Stack alignItems="flex-start" sx={{ mb: 2 }}>
            <Button
                size="small"
                variant="contained"
                onClick={handleOpenProject}
            >
                {t("Open Project")}
            </Button>
        </Stack>
    );
}
function FileAction() {
    const { t } = useLocales();
    return (
        <Stack
            spacing={1}
            direction="row"
            sx={{
                pl: 1,
                p: 1.5,
                borderRadius: 1.5,
                bgcolor: "background.neutral",
                mb: 2,
            }}
        >
            <FileThumbnail
                file="http://localhost:8080/httpsdesign-suriname-2015.mp3"
                sx={{ width: 40, height: 40 }}
            />

            <Stack
                spacing={1}
                direction={{ xs: "column", sm: "row" }}
                flexGrow={1}
                sx={{ minWidth: 0 }}
            >
                <ListItemText
                    disableTypography
                    primary={
                        <Typography
                            variant="subtitle2"
                            component="div"
                            sx={{ color: "text.secondary" }}
                            noWrap
                        >
                            design-suriname-2015.mp3
                        </Typography>
                    }
                    secondary={
                        <Stack
                            direction="row"
                            alignItems="center"
                            sx={{
                                typography: "caption",
                                color: "text.disabled",
                            }}
                            divider={
                                <Box
                                    sx={{
                                        mx: 0.5,
                                        width: 2,
                                        height: 2,
                                        borderRadius: "50%",
                                        bgcolor: "currentColor",
                                    }}
                                />
                            }
                        >
                            <span>2.3 GB</span>
                            <span>30 min ago</span>
                        </Stack>
                    }
                />

                <Button size="small" variant="outlined">
                    {t("Download")}
                </Button>
            </Stack>
        </Stack>
    );
}
function TagsAction() {
    const { t } = useLocales();
    return (
        <Stack direction="row" spacing={0.75} flexWrap="wrap" sx={{ mb: 2 }}>
            <Label variant="outlined" color="info">
                {t("Design")}
            </Label>
            <Label variant="outlined" color="warning">
                {t("Dashboard")}
            </Label>
            <Label variant="outlined">{t("Design system")}</Label>
        </Stack>
    );
}
function PaymentAction() {
    const { t } = useLocales();
    return (
        <Stack direction="row" spacing={1} sx={{ mb: 2 }}>
            <Button size="small" variant="contained">
                {t("Pay")}
            </Button>
            <Button size="small" variant="outlined">
                {t("Decline")}
            </Button>
        </Stack>
    );
}

export default NotificationDialog;
