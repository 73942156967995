import { useCallback, useEffect, useRef, useState } from "react";
import { useNotifications, useMagicBellEvent } from "@magicbell/react-headless";
import { m } from "framer-motion";
import Iconify from "src/components/iconify";
import { useLocales } from "src/locales";
import { useAuthContext } from "src/auth/hooks";
// @mui
import {
    Tooltip,
    Badge,
    Divider,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemText,
    Stack,
    Tab,
    Tabs,
    Typography,
} from "@mui/material";

import Scrollbar from "src/components/scrollbar";
import { useResponsive } from "src/hooks/use-responsive";
import NotificationItem from "./NotificationItem";
import { varHover } from "src/components/animate";
import NotificationDialog from "./NotificationDialog";
import { SplashScreen } from "src/components/loading-screen";

const TABS = [
    { storeId: "default", label: "All" },
    { storeId: "unread", label: "Unread" },
    // { storeId: "archived", label: "Archived" },
];
export default function NotificationsInbox({ onToggle, isOpen }) {
    const [currentTab, setCurrentTab] = useState("unread");
    const { t, currentLang } = useLocales();
    const { user } = useAuthContext();
    const smUp = useResponsive("up", "sm");
    const store = useNotifications(currentTab);
    const { unreadCount, isEmpty, notifications, markAllAsRead } = store || {};
    const [dialogOpen, setDialogOpen] = useState(null);
    useMagicBellEvent("notifications.new", async (notification) => {
        // Verify user has enabled push notifications
        if (!user?.notificationAlert?.web) return;
        if (!("Notification" in window)) return;
        if (Notification.permission === "denied") return;
        if (Notification.permission === "default") {
            const permission = await Notification.requestPermission();
            if (permission !== "granted") return;
        }
        new Notification(notification.title, {
            body: notification.content,
            lang: currentLang.value,
            icon: "/assets/tz-notification-icon.png",
            badge: "/assets/tz-notification-icon.png",
        });
    });
    const handleChangeTab = useCallback((_, newValue) => {
        setCurrentTab(newValue);
    }, []);
    const handleOpenDialog = useCallback((notification) => {
        setDialogOpen(notification);
    }, []);
    const handleFetchNextPage = useCallback(() => {
        store?.fetchNextPage();
    }, [store]);

    if (!store) return null;
    return (
        <>
            <IconButton
                component={m.button}
                whileTap="tap"
                whileHover="hover"
                variants={varHover(1.05)}
                color={isOpen ? "primary" : "default"}
                onClick={onToggle}
            >
                {/* HDY CAMBIO ESTILO DE CAMPANITA */}
                <Badge badgeContent={unreadCount} color="error">
                    <Iconify
                        icon="hugeicons:ai-mail"
                        width={42}
                        height={42}
                        style={{
                            color: "#FAFAFA",
                            borderRadius: "10%",
                            marginRight: 2,
                        }}
                    />
                </Badge>
            </IconButton>
            <Drawer
                open={isOpen}
                onClose={onToggle}
                anchor="right"
                slotProps={{
                    backdrop: { invisible: true },
                }}
                PaperProps={{
                    sx: { width: 1, maxWidth: 420 },
                }}
            >
                {/* HEAD */}
                <Stack
                    direction="row"
                    alignItems="center"
                    sx={{ py: 2, pl: 2.5, pr: 1, minHeight: 68 }}
                >
                    <Iconify
                        icon="hugeicons:ai-mail"
                        width={42}
                        height={42}
                        style={{
                            color: "#aeaeae",
                            borderRadius: "10%",
                            marginRight: 15,
                        }}
                    />
                    <Typography variant="h6" sx={{ flexGrow: 1 }}>
                        {t("Notifications")}
                    </Typography>

                    {unreadCount > 0 && (
                        <Tooltip title="Mark all as read">
                            <IconButton
                                color="primary"
                                onClick={() => {
                                    markAllAsRead();
                                }}
                            >
                                <Iconify icon="eva:done-all-fill" />
                            </IconButton>
                        </Tooltip>
                    )}

                    {!smUp && (
                        <IconButton onClick={onToggle}>
                            <Iconify icon="mingcute:close-line" />
                        </IconButton>
                    )}
                </Stack>
                <Divider />
                {/* TABS */}
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{ pl: 2.5, pr: 1 }}
                >
                    <Tabs value={currentTab} onChange={handleChangeTab}>
                        {TABS.map((tab) => (
                            <Tab
                                key={tab.storeId}
                                value={tab.storeId}
                                label={
                                    <Badge
                                        // badgeContent={computeTabCount(tab.storeId)}
                                        badgeContent={0}
                                        color="error"
                                        variant="dot"
                                    >
                                        {t(tab.label)}
                                    </Badge>
                                }
                            />
                        ))}
                    </Tabs>
                </Stack>
                <Divider />
                {/* LIST */}
                <Scrollbar>
                    <List disablePadding>
                        {isEmpty ? (
                            <ListItem>
                                <ListItemText
                                    primary={t("No notifications available")}
                                    sx={{ py: 1 }}
                                />
                            </ListItem>
                        ) : (
                            notifications?.map((notification) => (
                                <NotificationItem
                                    key={notification.id}
                                    rawNotification={notification}
                                    handleOpenDialog={handleOpenDialog}
                                />
                            ))
                        )}
                    </List>
                    {store?.total > store?.notifications?.length && (
                        <InfiniteScrollIntersector
                            onIntersect={handleFetchNextPage}
                        />
                    )}
                </Scrollbar>
            </Drawer>
            <NotificationDialog
                open={Boolean(dialogOpen)}
                onClose={() => setDialogOpen(null)}
                notification={dialogOpen}
            />
        </>
    );
}

function InfiniteScrollIntersector({ onIntersect }) {
    const container = useRef();
    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        onIntersect();
                    }
                });
            },
            {
                threshold: 0.3,
            },
        );
        observer.observe(container.current);
        return () => {
            observer.disconnect();
        };
    }, [onIntersect]);
    return (
        <div ref={container} className="w-full min-h-[130px] flex items-center">
            <div className="w-full scale-[0.6]">
                <SplashScreen
                    sx={{
                        width: "100%",
                        height: "100%",
                        position: "relative",
                        zIndex: 0,
                        bgcolor: "transparent",
                    }}
                />
            </div>
        </div>
    );
}
