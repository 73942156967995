const ClientViewPricesOptions=[
    {
        label:"Show Total Prices",
        value:"showTotalPrice",

    },
    {
        label:"Show Prices Per Person",
        value:"showPricePerPerson",
    },
    {
        label:"Dont Show Prices",
        value:"DontShowPrices",
    }
]

export {
    ClientViewPricesOptions
}